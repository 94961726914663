.tooltip
  @apply absolute left-1/2 -translate-x-1/2
  @apply text-center font-neutral bg-grey-200 z-50 min-w-min rounded-[5px]
  @apply py-[4px] px-[10px] mt-[9px]
  @apply select-none

  :global(.dark) &
    @apply bg-grey-300

  &::before
    @apply content-[''] absolute w-0 h-0
    @apply top-[-8px]
    @apply border-l-[10px] border-l-transparent border-solid
    @apply border-r-[10px] border-r-transparent
    @apply border-b-[8px] border-b-transparent

  &::after
    @apply content-[''] absolute h-0 w-0 border-solid
    @apply border-l-[8px] border-l-transparent
    @apply border-r-[8px] border-r-transparent
    @apply border-b-[8px] border-b-grey-200
    @apply top-[-8px] ml-[-8px] left-1/2

    :global(.dark) &
      @apply border-b-grey-300

  .text
    :global(.dark) &
      @apply text-black

.wrapper {
  @apply relative w-full;

  max-width: 310px;
  height: 48px;
}

.selectBox,
.selectNative {
  @apply absolute top-0 left-0 w-full h-full text-black;
}

:global(.dark) .selectBox {
  @apply text-white;
}

.selectNative {
  @apply w-full h-full p-0 opacity-0 pointer-events-none md:pointer-events-auto border-none;
}

/* This media query detects devices where the primary input mechanism */
/* can hover over elements. (e.g. computers with a mouse) */
/* Let's hide native select on such devices */
@media (hover: hover) {
  .selectNative {
    @apply pointer-events-none;
  }
}

.selectBox {
  @apply flex justify-between items-center bg-white
    border border-grey-200 transition;

  padding: 12px 0 16px 25px;
  border-radius: 5px;
}

.selectBox .iconWrapper {
  @apply mt-[-6px];
}

.selectBoxText {
  @apply flex items-baseline h-full;
}

:global(.dark) .selectBox {
  @apply bg-grey-800 border-grey-700;
}

.tool {
  @apply flex items-baseline text-blue-500 transition cursor-pointer;

  margin: 0 8px;
}

:global(.dark) .tool {
  @apply text-blue-200;
}

.tool strong {
  @apply font-semibold;
}

.icon {
  @apply select-none;

  height: 100%;
  width: 12px;
  margin-left: 6px;
  margin-bottom: -2px;
}

.selectBox.isOpen .icon {
  @apply transform rotate-180;
}

.tool:focus,
.tool:hover,
.isOpen .tool {
  @apply text-green-800;
}

:global(.dark) .tool:focus,
:global(.dark) .tool:hover,
:global(.dark) .isOpen .tool {
  @apply text-green-500;
}

.selectCustom {
  @apply absolute w-full p-0 text-black bg-white border border-grey-200 transition;

  /* Position box with options 6px below the clickable box */
  transform: translateY(100%); /* Moves box down by 100% of its height */
  bottom: -6px;
  left: 0;

  border-radius: 5px;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
}

:global(.dark) .selectCustom {
  @apply text-white bg-grey-800 border-grey-700;
}

.selectCustom.isOpen {
  opacity: 1;
  pointer-events: all;
}

.option {
  @apply relative cursor-pointer;

  padding: 12px 0 16px 25px;
}

.option:not(:last-of-type)::after {
  @apply absolute w-full bottom-0 left-0 border-b border-grey-200;

  content: '';
}

:global(.dark) .option:not(:last-of-type)::after {
  @apply border-grey-700;
}

.option.selected .optionText,
.option:hover .optionText {
  @apply font-semibold text-green-800;
}

:global(.dark) .option.selected .optionText,
:global(.dark) .option:hover .optionText {
  @apply text-green-500;
}

.copyWrapper {
  @apply absolute right-[6px] top-[13px];
}

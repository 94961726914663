.button
  @apply inline-block relative opacity-0 pointer-events-none transition-all

  &.visible
    @apply opacity-100 pointer-events-auto

  .iconWrapper
    @apply cursor-pointer h-[30px] w-[28px] transition-all

  .checkmarkIcon
    @apply pb-[2px] mt-[4px]

  .tooltip
    @apply opacity-0 pointer-events-none min-w-[60px] transition-opacity

  &:hover,
  &:focus
    .tooltip
      @apply opacity-100 pointer-events-auto

  &.copied
    @apply pointer-events-none

    .tooltip
      @apply block min-w-[78px]

.button.regular
  .iconWrapper
    @apply pt-[7px] px-[8px] rounded-[4px]
    @apply bg-grey-100 border border-grey-200

    :global(.dark) &
      @apply bg-grey-700 border border-grey-500

  &:hover .iconWrapper
    @apply bg-grey-200

    :global(.dark) &
      @apply bg-grey-500

  .icon
    @apply text-grey-800

    :global(.dark) &
      @apply text-grey-100

.button.borderless
  .iconWrapper
    @apply pt-[6px] px-[7px]

  .icon
    :global(.dark) &
      @apply text-grey-200

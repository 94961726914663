.wrapper {
  @apply flex flex-col items-center justify-center
    md:flex-row md:justify-center;
}

.wrapper > * + * {
  margin-top: 32px;
}

@screen md {
  .wrapper {
    margin-bottom: 80px;
    padding: 0 30px;
  }

  .wrapper > * + * {
    margin-top: 0;
  }
}

@screen xl {
  .wrapper {
    margin-bottom: 160px;
  }
}

/* Wrapper reverse */

.wrapper.reverse {
  @apply flex-col-reverse md:flex-row-reverse;

  margin-top: 60px;
}

/* .wrapper.reverse > * + * {
  margin-top: 120px;
} */

@screen md {
  .wrapper.reverse {
    margin-top: 160px;
    margin-bottom: 0;
  }
}

@screen xl {
  .wrapper.reverse {
    margin-top: 80px;
    margin-bottom: 0;
  }
}

/* Text content and buttons */

.content {
  @apply md:order-2;
}

.content.reverse {
  margin-top: 28px;
}

.content > * + * {
  margin-top: 36px;
}

.heading {
  /*
    Need to overwrite '.content > * + *' margin styles.
    @TODO: Get rid of it and migrate to the SASS approach.
  */
  @apply mt-[12px] !important;

  white-space: pre-line;
}

.description {
  margin-top: 4px;
}

.buttons {
  @apply w-full flex justify-between items-baseline
    md:justify-start;

  row-gap: 16px;
  column-gap: 23px;
}

.buttons > * {
  flex: 1;
}

.appButtons {
  @apply flex flex-col;
}

.appButtons > * + * {
  margin-top: 16px;
}

.appSectionLink {
  @apply col-start-2 col-end-2;

  align-self: center;
}

.content .earlyAccessBadge {
  @apply bg-grey-200 hover:bg-grey-300;
}

:global(.dark) .content .earlyAccessBadge {
  @apply bg-grey-800 hover:bg-grey-700;
}

@screen md {
  .content {
    width: 100%;
    max-width: 380px;
    margin-left: 60px;
  }

  .content.reverse {
    margin-right: 32px;
    margin-left: 0;
  }

  .content > * + * {
    margin-top: 28px;
  }

  .description {
    margin-top: 18px;
  }

  .buttons > * {
    width: 146px;
  }
}

@screen xl {
  .content {
    margin-left: 240px;
  }

  .content.reverse {
    margin-right: 180px;
    margin-left: 0;
  }

  .content > * + * {
    margin-top: 48px;
  }

  .content.reverse > * + * {
    margin-top: 36px;
  }

  .description {
    margin-top: 8px;
  }

  .buttons > * {
    width: 180px;
  }
}

/* App Image with Green Tile and Tweet card */
/* Mobile widths/heights/margins are calculated with the use of aspect ratios and percentages */

.imageWrapper {
  @apply relative md:order-1;

  width: 100%;
  max-width: 320px;

  padding-top: calc(32 / 343 * 100%);
  padding-left: 60px;
  padding-right: 44px;
}

.image {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(195 / 235 * 100%); /* aspect ratio of mobile asset */

  background-image: url('/Images/app-promo-mobile.png');
  background-size: cover;
  background-position: 50% 50%;
}

.greenTile {
  @apply absolute select-none;

  width: calc(56 / 235 * 100%);
  height: 0;
  padding-top: 100%; /* 1:1 aspect ratio */

  right: calc(42 / 235 * -100%);
  top: calc(118 / 195 * -100%);

  pointer-events: none;
}

.tweet {
  @apply absolute;

  width: calc(250 / 235 * 100%);

  left: calc(54 / 235 * -100%);
  bottom: calc(104 / 195 * -100%);
}

@screen md {
  .imageWrapper {
    max-width: fit-content;
    padding: 0;
  }

  .image {
    width: 286px;
    height: 284px;
    padding-top: 0;
    background-image: url('/Images/app-promo-tablet.png');
  }

  .greenTile {
    padding: 0;

    width: 56px;
    height: 56px;

    left: -32px;
    top: -26px;
  }

  .wrapper.reverse .greenTile {
    right: -32px;
    bottom: -26px;
    left: auto;
    top: auto;
  }

  .tweet {
    padding: 0;

    width: 224px;
    height: 121px;

    left: auto;
    right: -32px;
    bottom: -86px;
  }
}

@screen xl {
  .image {
    width: 480px;
    height: 400px;
    background-image: url('/Images/app-promo-desktop.png');
  }

  .greenTile {
    width: 95px;
    height: 95px;

    left: -56px;
    top: -60px;
  }

  .wrapper.reverse .greenTile {
    right: -56px;
    bottom: -60px;
    left: auto;
    top: auto;
  }

  .tweet {
    width: 360px;
    height: 175px;

    left: auto;
    right: -116px;
    bottom: -102px;
  }

  .wrapper.reverse .tweet {
    right: auto;
    left: -116px;
  }
}

.badge
  @apply inline-flex items-center pt-1 pl-1 pb-1 pr-3 transition-colors
  @apply rounded-full bg-grey-100 select-none

  :global(.dark) &
    @apply bg-grey-800

  .text
    @apply font-neutral px-[8px]

  .arrow
    @apply w-[10px] h-[10px] md:w-[8px] md:h-[8px] lg:w-[10px] lg:h-[10px]
    @apply opacity-60 transition-all

  .pill
    @apply w-[26px] h-[26px] md:w-[20px] md:h-[20px]  lg:w-[26px] lg:h-[26px]

    .pillText
       @apply leading-[24px] md:leading-[18px] lg:leading-[24px]

    &.pingAnimation::after
      @apply w-[26px] h-[26px] md:w-[20px] md:h-[20px] lg:w-[26px] lg:h-[26px]


.pill
  @apply relative inline-flex justify-center w-[19px] h-[19px]
  @apply bg-pink rounded-full transition-colors

  .pillText
    @apply text-black font-median leading-[18px] transition-colors

    :global(.dark) &
      @apply text-black

.pingAnimation
   &::after
    @apply content-[''] absolute w-[19px] h-[19px]
    @apply rounded-full bg-pink animate-ping-slow

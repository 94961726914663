.wrapper {
  @apply flex flex-col sm:flex-row items-center;

  margin-top: 4px;
}

/*
  Lottie animation has some paddings around them which makes them hard to position.
  Some negative margins and percentage width values are applied below to fix that.
*/

.animation {
  /* Fixing paddings & sizing */
  width: 106%;
  margin-left: -6.7% !important;
  margin-top: -16.7% !important;
}

.animationWrapper {
  /* Fixing paddings & sizing */
  margin-bottom: -20%;
  width: 100%;
  min-height: 300px;
  pointer-events: none;
}

@screen sm {
  .wrapper {
    margin-top: 40px;
    margin-bottom: 120px;
  }

  .animation {
    /* Fixing paddings & sizing */
    max-width: 949px;
    width: 108%;
    margin-left: -7.8% !important;
    margin-top: -13.8% !important;
  }

  .animationWrapper {
    /* Fixing paddings & sizing */
    max-width: 812px;
    width: 100%;
    height: 100%;
  }
}

@screen xl {
  .animationWrapper {
    min-height: 770px;
  }
}

.textSection {
  margin-bottom: 48px;
}

.textSection > * + * {
  margin-top: 20px;
}

.headline {
  margin: 46px 0 0 0;
  white-space: initial;
}

.body {
  max-width: 80%;
  white-space: initial;
}

@screen sm {
  .playerWrapper {
    margin-top: -94px;
    margin-left: -68px;
  }

  .textSection {
    margin-left: 45px;
  }

  .headline {
    margin-top: 0;
    white-space: pre-wrap;
  }

  .body {
    max-width: 160px;
    white-space: initial;
  }
}

@screen lg {
  .textSection {
    margin-left: 80px;
  }

  .textSection > * + * {
    margin-top: 30px;
  }

  .body {
    max-width: 100%;
    white-space: pre-wrap;
  }
}

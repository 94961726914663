.wrapper {
  @apply flex flex-col items-center justify-center
    md:flex-row md:justify-center;

  margin-top: 80px;
}

.wrapper > * + * {
  margin-top: 120px;
}

@screen md {
  .wrapper {
    padding: 0 30px;
  }

  .wrapper > * + * {
    margin-top: 0;
  }
}

/* Wrapper reverse */

.wrapper.reverse {
  @apply flex-col-reverse md:flex-row-reverse;

  margin-top: 0px;
}

.wrapper.reverse > * + * {
  margin-top: 0;
}

@screen md {
  .wrapper.reverse {
    margin-top: 32px;
    margin-bottom: 80px;
    padding: 0 30px;
  }

  .wrapper.reverse > * + * {
    margin-top: 0;
  }
}

@screen xl {
  .wrapper.reverse {
    margin-bottom: 160px;
  }

  .wrapper.reverse > * + * {
    margin-top: 32px;
  }
}

/* Text content and buttons */

.content {
  @apply md:order-1;
}

.content.reverse > * + * {
  margin-top: 28px;
}

.heading {
  white-space: pre-line;
}

.description {
  margin-top: 4px;
}

.button {
  display: inline-block;
  width: 186px;
  margin-top: 32px;
}

.appButtons {
  @apply flex flex-col;
}

.appButtons > * + * {
  margin-top: 16px;
}

.appSectionLink {
  @apply col-start-2 col-end-2;

  align-self: center;
}

@screen md {
  .content {
    width: 100%;
    max-width: 380px;
    margin-right: 32px;
  }

  .content.reverse {
    margin-left: 60px;
    margin-right: 0;
  }

  .content > * + * {
    margin-top: 28px;
  }

  .content.reverse > * + * {
    margin-bottom: 28px;
  }

  .description {
    font-size: 14px;
    margin-top: 18px;
  }

  .buttons > * {
    width: 146px;
  }

  .buttons > * + * {
    margin-left: 20px;
  }
}

@screen xl {
  .content {
    margin-right: 180px;
  }

  .content.reverse {
    margin-left: 240px;
    margin-right: 0;
  }

  .content > * + * {
    margin-top: 36px;
  }

  .content.reverse > * + * {
    margin-bottom: 48px;
  }

  .description {
    margin-top: 8px;
  }

  .buttons > * {
    width: 180px;
  }
}

/* App Image with Green Tile and Tweet card */
/* Mobile widths/heights/margins are calculated with the use of aspect ratios and percentages */

.imageWrapper {
  @apply relative md:order-2;

  width: 100%;
  max-width: 320px;

  padding-top: calc(32 / 343 * 100%);
  padding-left: 60px;
  padding-right: 44px;
  margin-bottom: 5%;
}

.image {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(191 / 234 * 100%); /* aspect ratio of mobile asset */

  background-image: url('/Images/cli-promo-mobile.png');
  background-size: cover;
  background-position: 50% 50%;
}

.blackTile {
  @apply absolute select-none;

  width: calc(56 / 234 * 100%);
  height: 0;
  padding-top: 100%; /* 1:1 aspect ratio */

  right: calc(42 / 234 * -100%);
  top: calc(118 / 191 * -100%);

  pointer-events: none;
}

.tweet {
  @apply absolute rounded-2xl;
  width: calc(250 / 235 * 100%);

  right: calc(48 / 234 * -100%);
  bottom: calc(104 / 191 * -100%);
}

.tweet .tweet-text {
  @apply text-sm lg:text-[15px];
}

.tweet .tweet-name {
}

@screen md {
  .imageWrapper {
    max-width: fit-content;
    padding: 0;
    margin-bottom: 0;
    margin-top: -72px;
  }

  .image {
    width: 287px;
    height: 235px;
    padding-top: 0;
    background-image: url('/Images/cli-promo-tablet.png');
  }

  .blackTile {
    padding: 0;

    width: 56px;
    height: 56px;

    right: -32px;
    left: auto;
    bottom: -26px;
    top: auto;
  }

  .wrapper.reverse .blackTile {
    left: -32px;
    top: -26px;
    right: auto;
    bottom: auto;
  }

  .tweet {
    padding: 0;

    width: 224px;

    left: -32px;
    right: auto;
    bottom: -108px;
  }
}

@screen xl {
  .image {
    width: 480px;
    height: 335px;
    background-image: url('/Images/cli-promo-desktop.png');
  }

  .blackTile {
    width: 95px;
    height: 95px;

    right: -46px;
    bottom: -48px;
  }

  .wrapper.reverse .blackTile {
    left: -56px;
    top: -60px;
    right: auto;
    bottom: auto;
  }

  .tweet {
    width: 360px;
    height: 175px;

    left: -56px;
    bottom: -160px;
  }

  .wrapper.reverse .tweet {
    right: -118px;
    left: auto;
  }
}

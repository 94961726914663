.wrapper {
  @apply flex flex-col justify-around;

  width: 100%;
  min-height: 1360px;
  margin: 100px 0 120px 0;
  padding: 44px 0 20px 0;

  background-image: url('/Images/pie-background-light.svg');
  background-position: 50% 50%;
  background-size: cover;
}

:global(.dark) .wrapper {
  background-image: url('/Images/pie-background-dark.svg');
}

.wrapper.reverse {
  @apply flex-col-reverse;
}

@screen md {
  .wrapper {
    padding: 44px 0 54px 0;
    background-position: 53% 50%;
    margin: 60px 0 120px 0;
    min-height: 884px;
  }
}

@screen xl {
  .wrapper {
    min-height: 1470px;
    margin-top: 140px;
    padding-top: inherit;
  }
}
